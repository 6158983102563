/* src/Header.css */
.header {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    background-color: #e6e0cf;
    border-bottom: 1px solid #e6e0cf;
}

.logo {
    width: 200px; /* Adjust as needed */
    height: 200px; /* Adjust as needed */
    margin-right: 20px; /* Space between logo and text */
}

.title {
    font-size: 24px;
    line-height: 1; /* Adjust line height as needed */
}

.top-line {
    color: #b19963;
    display: block;
}

.line {
    color: #173150;
    display: block;
}  
