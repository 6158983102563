.blue-div {
    background-color: #173150;
    padding: 20px;
}

.paragraph-text {
    color: #fff;
}
/* Modal.css or Global.css */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure it is above other content */
  }
  
  .modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    z-index: 1001; /* Ensure it is above the overlay */
  }
  
  .blur-background {
    filter: blur(5px);
    transition: filter 0.3s ease;
  }
  
  