.mobile-container {
    padding: 20px;
  }
  
  .option-container {
    margin: 15px 0;
  }
  
  .option-container label {
    display: block;
    font-size: 16px;
    margin-bottom: 10px;
  }
  
  .slider-container {
    margin-top: 20px;
  }
  
  .slider-group {
    margin-bottom: 20px;
    padding: 15px;
    border: 1px solid #ccc;
    border-radius: 8px;
  }
  
  .slider-group label {
    display: block;
    font-size: 16px;
    margin-bottom: 5px;
  }
  
  .slider-values {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  
  .slider-results {
    margin-top: 10px;
  }
  
  .slider-results p {
    margin: 0;

  }
  
  .small-text-container {
    display: flex;
    justify-content: space-between;
  }
  
  .small-text {
    font-size: 0.9em;
    color: white;
    margin: 0 8px;
  }
  
  .button-group {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;
  }
  
  .option-button {
    padding: 12px;
    background-color: #4CAF50;
    color: white;
    border: none;
    cursor: pointer;
    text-align: center;
    border-radius: 5px;
  }
  
  .option-button:hover {
    background-color: #45a049;
  }
  
  @media screen and (max-width: 767px) {
    .mobile-container {
      padding: 5px;
    }
  
    .option-container label {
      font-size: 14px;
    }
  
    .slider-group label {
      font-size: 14px;
    }
  
    .slider-results {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .small-text-container {
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      font-size: 8px;
    }
  
    .small-text {
      margin: 5px 10px;
      font-size: 14px;
    }
  
    .option-button {
      padding: 10px;
    }
  }