.blurred {
  filter: blur(5px);
  pointer-events: none;
}

.vertical-stepper-container {
  background-color: transparent;
  padding: 20px;
  border-radius: 8px;
  margin: 20px 0;
}

.steps {
  display: flex;
  flex-direction: column;
}

.step {
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
}

.step-title {
  font-size: 1.5em;
  margin-bottom: 10px;
  color: white;
}

.step-content {
  margin-bottom: 20px;
}

.step-content img {
  max-width: 100%;
  margin: 10px 0;
}

.button-group {
  display: flex;
  justify-content: space-between;
}

.button-group button {
  background-color: rgb(177, 153, 98); /* Background color */
  color: white; /* Text color */
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.button-group button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.button-group button:hover:not(:disabled) {
  background-color: #60ab55;
}

.option-button {
  background-color: #b19963;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  margin: 10px 0;
  cursor: pointer;
  border-radius: 12px;
  transition: background-color 0.3s;
}

.option-button:hover {
  background-color: #155bb5;
}

.step.active .step-title {
  font-weight: bold;
}

.step-content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.column-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}

.column-container > div {
  flex: 1;
  margin: 0 10px; /* Space between columns */
}

.column-container img.small-img {
  width: 300px; /* Adjust the size as needed */
  height: auto;
}

.step-content-container p {
  margin: 0;
}

.step-content-container button.option-button {
  margin-top: 20px; /* Space above the button */
  background-color: #b19963;
  color: black;
  font-weight: 500;
}
.step-text {
  text-align: left; /* Left justify the text */
  color: white; /* Change the color to white */
  font-size: 1em; /* Increase the font size, adjust as needed */
  margin-right: 20px;
}

.option-container label {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 10px;
}

.option-container input {
  margin-right: 10px;
}

.option-button {
  background-color: rgb(177, 153, 98); /* Background color */
  color: white; /* Text color */
  border: none; /* Remove border */
  padding: 10px 20px; /* Padding */
  margin-top: 20px; /* Margin between buttons */
  border-radius: 12px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor on hover */
  font-size: 1em; /* Font size */
}

.option-button:hover {
  background-color: darken(rgb(177, 153, 98), 10%); /* Darken on hover */
}
.option-container {
  background-color: #6c6c6c; /* Light gray background */
  padding: 10px;
  color: white;
  border-radius: 8px; /* Rounded corners */
  margin: 10px 0; /* Margin between options */
  border: 1px solid #ddd; /* Border for better visibility */
}

.option-container label {
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
}

.option-container input {
  margin-right: 10px;
}
.slider-table {
  display: table;
  width: 100%;
  margin: 20px 0;
  border-collapse: collapse;
}

.slider-row {
  display: table-row;
}

.header-row {
  font-weight: bold;
  background-color: #515151;
}

.slider-cell {
  display: table-cell;
  padding: 10px;
  border: 1px solid #ccc;
  text-align: center;
}

.slider-total-row {
  display: table-row;
  font-weight: bold;
}

.option-container {
  margin: 10px 0;
}

.option-button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #4caf50;
  color: white;
  border: none;
  cursor: pointer;
}

.option-button:hover {
  background-color: #45a049;
}

.button-group {
  display: flex;
  justify-content: space-between;
}

/* Mobile responsive styles */
@media screen and (max-width: 768px) {
  .slider-table {
    display: block;
  }

  .slider-row {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }

  .header-row {
    display: none; /* Hide the header row on mobile */
  }

  .slider-cell {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border: 1px solid #ccc;
  }

  .slider-total-row {
    display: block;
    font-weight: bold;
  }

  .button-group {
    flex-direction: column;
    gap: 10px;
  }

  .option-button {
    width: 100%;
    padding: 15px;
  }
}
.pdf-viewer {
  width: 100%;
  height: 400px;
  border: 1px solid #ccc;
  overflow: auto;
}
.pdf-container {
  position: relative;
  width: 100%;
  max-width: 400px; /* Adjust the max width as needed */
  margin: 0 auto;
  border: 1px solid #b19963;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  padding: 10px;
}

.pdf-canvas {
  width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}

.pdf-navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.pdf-navigation button {
  padding: 8px 12px;
  border: none;
  background-color: #b19963;
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.pdf-navigation button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.pdf-navigation button:not(:disabled):hover {
  background-color: #b19963;
}

.pdf-navigation span {
  flex-grow: 1;
  text-align: center;
  padding: 0 20px; /* Add padding here */
}

/* Mobile-Friendly Adjustments */
@media (max-width: 767px) {
  .pdf-container {
    padding: 5px;
  }

  .pdf-navigation {
    flex-direction: row;
  }

  .pdf-navigation button {
    margin: 0 5px; /* Adjust margin for spacing */
    width: auto; /* Ensure buttons are not 100% width */
  }

  .pdf-navigation span {
    padding: 10px 0;
  }

  .button-group {
    flex-direction: row; /* Ensure buttons are next to each other */
    justify-content: space-between; /* Adjust spacing */
  }

  .button-group .option-button {
    flex: 1;
    margin: 5px 2px; /* Adjust margin for spacing */
  }
}
.button-group {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.button-group .option-button {
  flex: 1;
  margin: 0 5px;
}

.button-group .option-button:first-child {
  margin-left: 0;
}

.button-group .option-button:last-child {
  margin-right: 0;
}

/* Mobile-Friendly Adjustments */
@media (max-width: 767px) {
  .button-group {
    flex-direction: column;
    align-items: stretch;
  }

  .button-group .option-button {
    margin: 5px 0;
  }
}
