/* src/assets/css/Modal.css */

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    background: #e6e0cf;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    max-width: 500px;
    width: 100%;
    color: black;
  }
  
  .modal-content h2 {
    margin-top: 0;
  }
  
  .modal-content p {
    margin: 20px 0;
  }
  
  .option-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
  }
  
  .option-button:hover {
    background-color: #0056b3;
  }
  
  .checkbox-container {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .checkbox-container input {
    margin-right: 10px;
  }
  
  textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    margin-bottom: 20px;
  }
  