.congrats-container {
  background: #173150;
  padding-block: 5rem;
  padding-inline: 7rem;
}

.congrats-header {
  color: #b19963;
}

.congrats-layout {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
}

.congrats-paragraphs {
  max-width: 500px;
}

/* make an image clamp down when responsive without */
.image-congrats {
  max-width: 500px;
  height: auto;
}

@media screen and (max-width: 1440px) {
  .congrats-layout {
    flex-direction: column;
  }

  .congrats-container {
    padding-block: 2rem;
    padding-inline: 2rem;
  }

  .image-congrats {
    max-width: 400px;
    height: auto;
  }
}
