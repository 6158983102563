/* src/components/components-home/ActivationForm.css */

.activation-form-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
  background-color: #e6e0cf;
  padding: 20px;
}

.activation-form-container {
  background-color: #002b54;
  padding: 20px;
  border-radius: 8px;
  color: #fff;
  text-align: center;
  width: 100%;
  max-width: 550px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.paragraph-text {
  font-size: 18px;
  margin-bottom: 20px;
}

.activation-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.form-group {
  margin-bottom: 15px;
  width: 100%;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  text-align: left;
}

.form-group input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.action-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
}

.wallet-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50px;

  img {
    width: 100%;
    height: 100%;
  }
}

.submit-button {
  width: 100%;
  padding: 12px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: #0056b3;
}

.error {
  color: #ff0000;
  margin-top: 10px;
}

.message {
  color: #00ff00;
  margin-top: 10px;
}
